@import url('https://fonts.googleapis.com/css2?family=Montserrat&family=Public+Sans&display=swap');
.start-body{
    /* background-image: linear-gradient(to right top, #72899d, #5a738a, #435e78, #2d4a66, #143655) !important;    */
    height: 750px;
    display: flex;
    width: auto;
    justify-content: center;
    grid-template-columns: 1fr 1fr;
    margin-top: 100px;
}
.flex-direction{
    flex-direction:row;
}

@media screen and (max-width: 975px) {
    .flex-direction{
        flex-direction:column;
    }
}
.big{
    display: flex;
    justify-content: center;
}
.callToAction{
    justify-content: center;
    align-items: center;
    display: flex;
    width: 275px;
    height: 50px;
    background: #F07A3D;
    border-radius: 10px;
    cursor: pointer;
    border: none;
    font-family: "system-ui";
    font-size: 1.5rem;
    font-weight: 700;
    color: white;
    transition: 0.3s;
    text-decoration: none;
}
.callToAction:hover {
    background-color: #eb553b;

}

.starter-image{
    margin-left: 200px;
    width: 100vw;
    max-width:512px; 
    max-height:512px;
}
@media screen and (max-width: 1350px) {
    .starter-image{
        margin-left: 0px;
        width: 100vw;
        max-width:512px; 
        max-height:512px;
    }
}
@media screen and (max-width: 975px) {
    .callToAction{
        margin: auto;
        margin-top: 25px;
        margin-bottom: 25px;
        width: 100%;
    }
    .text-and-button{
        margin: auto;
        text-align: center;
    }
    .image-div{
        margin: auto;
    }
    .starter-image{
        margin-left: 0px;
        width: 100vw;
        max-width:512px; 
        max-height:512px;
    }
}