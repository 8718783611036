@import url('https://fonts.googleapis.com/css2?family=Montserrat&family=Public+Sans&display=swap');
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', "Public Sans", sans-serif, 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image: linear-gradient(to right top, #72899d, #5a738a, #435e78, #2d4a66, #143655) !important;   
  color: black;
  /* background-image: linear-gradient(to right top, #72899d, #5f89b1, #4989c5, #3187d8, #1284eb); */
  /* background-image: linear-gradient(to right top, #72899d, #586e81, #405366, #283a4c, #112334);   */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body::-webkit-scrollbar {
  width: 17px;
}

body::-webkit-scrollbar-track {
  background: rgb(25, 37, 43);        /* color of the tracking area */
}

body::-webkit-scrollbar-thumb {
  background-color: rgb(78, 93, 136);    /* color of the scroll thumb */
  /* border-radius: 20px; */
  /* border: 3px solid rgb(95, 95, 95); */
}
