.update-entry{
    width: 95%;
    min-height: 25%;
    /* background-image: linear-gradient(to right top, #2e6294, #2a5782, #264c70, #22415f, #1f374e);*/
    background-image: linear-gradient(to right top, #193957, #183857, #163757, #153656, #133556);
    margin: auto;
    margin-top: 25px;
    margin-bottom: 40px;
    border-radius: 10px;
    /* opacity: 40%; */
    height: fit-content;
}