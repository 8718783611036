.main-body{
    min-height: 800px;
    height: fit-content; 
    width: auto; 
    display: flex;
    justify-content: center;
}

.review-box{
    width: 80%;
    background-image: linear-gradient(to right top, #f1853e, #f07a3c, #ef6e3b, #ed623a, #eb553b);
    border-radius: 25px;
    height: fit-content;
    min-height: 300px;
    min-width: 85%;
    /* display: table; */
    /* table-layout: fixed;  */
    text-align: center;
    /* text-align-last:center; */
}

.item{
    display: inline-block; 
    height: auto;
    margin: 75px;
    vertical-align: initial;
    max-width: 300px;
}

@media only screen and (max-width: 1000px) {
    .item{
        margin: 25px;
    }
    .review-box{
        margin-top: 100px;
        width: 95%;
    }
}
@media only screen and (max-height: 600px) {
    .review-box{
        margin-top: 0px;
    }
}

@media only screen and (max-width: 1000px) {
    .main-body{
        padding: 5%;
    }
    .item{
        margin-top: 5%;
    }
}

@media only screen and (max-width: 1250px) {
    .middle{
        padding-left: 100px;
        padding-right: 100px;
    }
}