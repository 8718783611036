.footer-display{
    display: flex;
    justify-content: center;
    margin-top: 75px;
}

.footer-item{
    cursor: pointer;
    text-align: center;
    margin: 0px 20px 15px;
}
@media only screen and (max-width: 350px) {
    .footer-item{
        margin-left: 5px;
        margin-right: 5px;
    }
}
.anchor:-webkit-any-link {
    text-decoration: none;
    color: inherit;
}