.error{
    height: calc(100vh - 220px);
    height: -o-calc(100% - 220px); /* opera */
    height: -webkit-calc(100% - 220px); /* google, safari */
    height: -moz-calc(100% - 220px); /* firefox */
    display: flex;
    justify-content: center;
    text-align: center;
}

.error-info{
    width: 85vw;
    max-width: 512px;
}

/* remove this if it stops working */
@media (min-width: 900px) and (max-width: 1500px) and (min-height: 550px) and (max-height: 650px) {
    .error{
        height: calc(100vh);
    }
}
@media (orientation:landscape) and (max-width: 1000px){
    .error{
        height: calc(100vw - 220px);
    }
}
@media (orientation:landscape) and (max-width: 670px){
    .error{
        height: calc(130vw - 220px);
    }
}