.updates{
    margin: auto;
    height: fit-content;
    min-height: calc(100vh - 220px);
    min-height: -o-calc(100% - 220px); /* opera */
    min-height: -webkit-calc(100% - 220px); /* google, safari */
    min-height: -moz-calc(100% - 220px); /* firefox */
    /* display: flex; */
    width: 80%;
    justify-content: center;
    text-align: center;
}
@media (max-width: 900px) {
    .updates{
        width: 95%;
    }
}

@media (max-width: 900px) {
    .about{
        width: 95%;
     }
 }
 .updates-table{
    padding: 15px;
    background-image: linear-gradient(to right top, #192e42, #142c43, #0f2a44, #0a2845, #052646);
    width: 90%;
    height: 700px;
    margin: auto;
    border-radius: 10px;
    overflow-y: auto;
 }
 .updates-table::-webkit-scrollbar{
    width: 7px;
    height: 7px;
}
  .updates-table::-webkit-scrollbar-track{
      background: #19252B; /*rgb(25, 37, 43)*/
  }
  .updates-table::-webkit-scrollbar-thumb {
      background-color: #4E5D88
  }